import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import MainView from './app/MainView'

export default class Main {

	init() {
		this.user = undefined
		this.loggedIn = false
		this.sessionId = null
		this.preloadJSON("/data/data.json")
		this.API = process.env.REACT_APP_API_URL
	}

	preloadJSON( dataURL) {
		fetch(dataURL)
		.then((response) => {
		    return response.json()
		})
		.then((result) => {
		    this.data = result
		    this.checkUser()
		    //this.ready()
		})
	}

	checkUser() {
		let data  = {}
		fetch(this.API+"/auth/describe", {
			method: "POST",
			headers: {
		        "Content-Type": "application/json; charset=utf-8",
		        "Authorization": "Bearer " + sessionStorage.getItem('token')
		    },
			body: JSON.stringify(data)
		})
		.then((response) => {
		    return response.json()
		})
		.then((result) => {
		    if (result.status?.success === true) {
		    	this.loggedIn = true
				this.user = { name: result.data.editor.firstName ? result.data.editor.firstName + " " + result.data.editor.lastName : result.data.editor.emailAddress, role: "ADMIN" }
				if (document.location.pathname === "/login") {
					window.history.replaceState("index.html", "Home page", "/")
				}
				this.ready()
		    } else {
		    	this.loggedIn = false
		    	this.user = { name: "" }
				window.history.replaceState("index.html", "Login page", "/login")
				this.ready()
				sessionStorage.removeItem('token')
		    }
		}, (error) => {
			console.log(error)
		})
	}

	ready() {
		this.render()
	}

	render() {
		const mainContainer = document.getElementById('app')

		ReactDOM.render(
			<Router>
				<MainView 
					user={ this.user } 
					loggedIn={ this.loggedIn } 
					API = { this.API }
					sessionId = { this.sessionId }
					data={ this.data }/>
			</Router>
		, mainContainer)
	}
}

const app = new Main()
app.init()