import React, { useState, useEffect } from 'react'
import Modules from './Modules'
import SingleUseModules from './SingleUseModules'
import { useNavigate, useParams } from 'react-router'
import Categories from './Categories'
import CategoriesOffers from './CategoriesOffers'
import CategoriesFAQ from './CategoriesFAQ'
import _ from 'lodash'
import Addresses from './Addresses'
import SelectTrack from './SelectTrack'
import SelectArticle from './SelectArticle'
import SelectOffer from './SelectOffer'
import SelectEvent from './SelectEvent'
import AddTerm from './AddTerm'
import { parseDate, errorMap, errorInputValidator } from './BaseComponent'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import { registerLocale } from "react-datepicker";
import pl from 'date-fns/locale/pl';
import MultiselectComponent from './Multiselect'

registerLocale('pl', pl)

export default function EditPage({ API, user, data }) {
  const { id } = useParams()
  let paramsObj = {}

  for (let i = 0; i < data.fields.length; i++) {
    const element = data.fields[i];
    const d = new Date();
    const dateTimeLocalValue = d.toISOString()
    _.set(paramsObj, element.name, element.type === "colorpicker" ? "#000000" : element.type === "checkbox" ? (element.checked ? true : false) : element.type === "number" ? 0 : element.type === "text" || element.type === "slug" || element.type === "textarea"  ? "" : element.type === "modules" ? [] : element.type === "date" ? dateTimeLocalValue : element.type === "datecontest" ? dateTimeLocalValue : element.type === "terms" ? {file: {uuid: "", restricted: true}} : null)
  }

  const history = useNavigate()
  const [preloader, setPreloader] = useState(false)
  const [params, setParams] = useState(paramsObj)
  const [errors, setErrors] = useState({})

  const getSlug = (name) => {
    let tempParams = {...params}
    let value = tempParams.content?.title ? tempParams.content.title : tempParams.content?.name ? tempParams.content.name : tempParams.name 
    if(value) {
      value = value.toLowerCase()
      var from = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆĞÍÌÎÏİŇÑÓÖÒÔÕØŘŔŠŞŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇğíìîïıňñóöòôõøðřŕšşťúůüùûýÿžþÞĐđßÆa·/_,:;ęĘóÓąĄśŚłŁżŻźŹćĆńŃ"
      var to   = "AAAAAACCCDEEEEEEEEGIIIIINNOOOOOORRSSTUUUUUYYZaaaaaacccdeeeeeeeegiiiiinnooooooorrsstuuuuuyyzbBDdBAa------eEoOaAsSlLzZzZcCnN"

      for (var i = 0, l = from.length; i < l; i++) {
        value = value.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
      }

      value = value.replace(/[^a-z0-9 -]/g, '')
              .replace(/\s+/g, '-')
              .replace(/-+/g, '-')
      _.set(tempParams, name, value)
      setParams(tempParams)
    }
  }

  const validateInput = (i) => {
    const input = i
    const name = input.name
    const value = input.value

    if(![name] || value === "" || value === false || value === "false") {
        let err = errors
        err[name] = "Pole wymagane"
        setErrors({...err})
        setTimeout(() => { document.querySelector("input[name='" + name + "']").scrollIntoView({behavior: "smooth", block: "center", inline: "center"})
          setTimeout(() => {
            document.querySelector("input[name='" + name + "']").focus()
          }, 300)
          setTimeout(() => {
            document.querySelector("input[name='" + name + "']")?.classList.add("error-single")
            setTimeout(() => {
              document.querySelector("input[name='" + name + "']")?.classList.remove("error-single")
            }, 3000)
          }, 300)
        }, 300)
        return false
      } else if(name !== "buildingNumber" && value.length < 3) {
        let err = errors
        err[name] = "Wpisz poprawną wartość"
        setErrors({...err})
        setTimeout(() => { document.querySelector("input[name='" + name + "']").scrollIntoView({behavior: "smooth", block: "center", inline: "center"})
          setTimeout(() => {
            document.querySelector("input[name='" + name + "']").focus()
          }, 300)
        }, 300)
        return false
      } else {
        let err = errors
        err[name] = false
        setErrors({...err})
        return true
    }
  }

  const validateForm = (callback) => {
    let wrongCounter = 0
    const inputs = document.querySelectorAll('input:required')

    for (let i = inputs.length-1; i >= 0; i--) {
        const input = inputs[i];
        if(!validateInput(input)) {
            wrongCounter++
        }
    }

    if(wrongCounter === 0) {
      callback()
    }
  }

  const save = () => {
    setPreloader(true)
    if(params.priority) {
      params.priority = parseInt(params.priority)
    }

    let payload = {
      [data.table]: params
    }

    if(params.content?.modules && _.filter(params.content?.modules, { type: "address" }).length > 0) {
      if(params.content.modules[3].metadata.latitude === "") params.content.modules[3].metadata.latitude = null
      if(params.content.modules[3].metadata.longitude === "") params.content.modules[3].metadata.longitude = null
    }

    let url = API+data.endpointEdit
    fetch(url, {
      method: "POST",
      headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": "Bearer " + sessionStorage.getItem('token')
      },
      body: JSON.stringify(payload)
    })
    .then((response) => {
        return response.json()
    })
    .then((result) => {
      if (!result.status.success) {
        errorInputValidator(result.data?.error?.ident)
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : errorMap(result.data?.error?.ident)}}), true);
      } else {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Pomyślnie zapisano."}}), true);
      }
      setPreloader(false)
    }, (error) => {
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Something went wrong."}}), true);
      setPreloader(false)
    })
  }

  useEffect(() => {
    if (user.role !== "ADMIN" && user.role !== "Moderator") {
      let elements = document.querySelectorAll("input, textarea, select")
      elements.forEach((element) => {
        element.disabled = true
      })
    }

    const params = {
      [data.table]: {
        uuid: id
      }
    }

    let url = API+data.endpointDetails

    fetch(url, {
      method: "POST",
      headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": "Bearer " + sessionStorage.getItem('token')
        },
        body: JSON.stringify(params)
      })
    .then((response) => {
        return response.json()
    })
    .then((result) => {

      if(data.table === "catalogue") {
        if(!result.data.content) {
          result.data.content = {}
          result.data.content.ignore = false
        }
      }

      if(data.singleUseModules && data.singleUseModules.length > 0 && result.data.content && result.data.content.modules?.length > 0) {
        let introCount = 0, tmCount = 0, tdCount = 0, cmCount = 0, cdCount = 0

        for (let i = 0; i < result.data.content.modules.length; i++) {
          const element = result.data.content.modules[i];

          if(element.type === "intro") introCount++
          if(element.type === "hero_image") tmCount++
          if(element.type === "thumbnail") tdCount++
          if(element.type === "cover_image_mobile") cmCount++
          if(element.type === "cover_image_desktop") cdCount++

          if(data.singleUseModules.includes(element.type)) {
            result.data.content.modules[i].singleUse = true
          }
        }

        if(cdCount === 0 && data.singleUseModules.includes("cover_image_desktop")) {
          result.data.content.modules.unshift({ type: "cover_image_desktop", singleUse: true, metadata: {} })
        }
        if(cmCount === 0 && data.singleUseModules.includes("cover_image_mobile")) {
          result.data.content.modules.unshift({ type: "cover_image_mobile", singleUse: true, metadata: {} })
        }
        if(tdCount === 0 && data.singleUseModules.includes("thumbnail")) {
          result.data.content.modules.unshift({ type: "thumbnail", singleUse: true, metadata: {} })
        }
        if(tmCount === 0 && data.singleUseModules.includes("hero_image")) {
          result.data.content.modules.unshift({ type: "hero_image", singleUse: true, metadata: {} })
        }
        if(introCount === 0 && data.singleUseModules.includes("intro")) {
          result.data.content.modules.unshift({ type: "intro", singleUse: true })
        }

        if(data.singleUseModules.includes("intro") && _.findIndex(result.data.content.modules, { type: "intro" }) !== 0) {
          const index = _.findIndex(result.data.content.modules, { type: "intro" })
          const element = {...result.data.content.modules[index]}
          const oldElement = {...result.data.content.modules[0]}
          result.data.content.modules[index] = oldElement
          result.data.content.modules[0] = element
        }

        if(data.singleUseModules.includes("hero_image") && _.findIndex(result.data.content.modules, { type: "hero_image" }) !== 1) {
          const index = _.findIndex(result.data.content.modules, { type: "hero_image" })
          const element = {...result.data.content.modules[index]}
          const oldElement = {...result.data.content.modules[1]}
          result.data.content.modules[index] = oldElement
          result.data.content.modules[1] = element
        }

        if(data.singleUseModules.includes("thumbnail") && _.findIndex(result.data.content.modules, { type: "thumbnail" }) !== 2) {
          const index = _.findIndex(result.data.content.modules, { type: "thumbnail" })
          const element = {...result.data.content.modules[index]}
          const oldElement = {...result.data.content.modules[2]}
          result.data.content.modules[index] = oldElement
          result.data.content.modules[2] = element
        }
        if(data.singleUseModules.includes("cover_image_mobile") && _.findIndex(result.data.content.modules, { type: "cover_image_mobile" }) !== 3) {
          const index = _.findIndex(result.data.content.modules, { type: "cover_image_mobile" })
          const element = {...result.data.content.modules[index]}
          const oldElement = {...result.data.content.modules[3]}
          result.data.content.modules[index] = oldElement
          result.data.content.modules[3] = element
        }
        if(data.singleUseModules.includes("cover_image_desktop") && _.findIndex(result.data.content.modules, { type: "cover_image_desktop" }) !== 4) {
          const index = _.findIndex(result.data.content.modules, { type: "cover_image_desktop" })
          const element = {...result.data.content.modules[index]}
          const oldElement = {...result.data.content.modules[4]}
          result.data.content.modules[index] = oldElement
          result.data.content.modules[4] = element
        }

      } else if(data.singleUseModules && data.singleUseModules.length > 1) {
        for (let i = 0; i < data.singleUseModules.length; i++) {
          const element = data.singleUseModules[i];
          let tempParams= _.cloneDeep(result.data)
          let tempModules = _.get(tempParams, "content.modules") || []
          tempModules.push({ type: element, singleUse: true })
          _.set(tempParams, "content.modules", tempModules)
          result.data = tempParams
        }
      }

      setParams({...result.data})
    }, (error) => {
      console.log(error)
    })

    // eslint-disable-next-line
  }, [])

  const fields = data.fields.map((item, i) => {
    if (item.type === "text") {
      return (
        <div key={ i } data-moduletype={item.name.split(".")[item.name.split(".").length-1]} data-moduleindex={i}>
          <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
          { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
          <input type="text" name={ item.name } value={ _.get(params, item.name) || "" } onChange={ (e) => { let tempParams = {...params}; _.set(tempParams, item.name, e.target.value); setParams(tempParams) } } required={ item.required ? true : false } disabled={ item.disabled ? true : false }/>
        </div>
      )
    } else if (item.type === "select") {
      return (
        <div key={ i } data-moduletype={item.name.split(".")[item.name.split(".").length-1]} data-moduleindex={i}>
          <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
          { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
          <select  name={ item.name } value={ _.get(params, item.name) || "" } onChange={ (e) => { let tempParams = {...params}; _.set(tempParams, item.name, e.target.value); setParams(tempParams) } } required={ item.required ? true : false } disabled={ item.disabled ? true : false }>
            { item.values.map((option, j) => {
              return (
                <option key={j} value={option.val}>{option.name}</option>
              )
            }) }
          </select>
        </div>
      )
    } else if (item.type === "colorpicker") {
      return (
        <div key={ i } data-moduletype={item.name.split(".")[item.name.split(".").length-1]} data-moduleindex={i}>
          <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
          { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
          <input type="color" name={ item.name } value={ _.get(params, item.name) } onChange={ (e) => { let tempParams = {...params}; _.set(tempParams, item.name, e.target.value); setParams(tempParams) } } required={ item.required ? true : false }/>
        </div>
      )
    } else if (item.type === "slug") {
      return (
        <div key={ i } data-moduletype={item.name.split(".")[item.name.split(".").length-1]} data-moduleindex={i} className="slug-input">
          {/* { item.desc ? <p className="input-desc" dangerouslySetInnerHTML={{ __html: item.desc }}></p> : null } */}
          <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
          <div className="flex-input">
            <input type="text" name={ item.name } value={ _.get(params, item.name) } onChange={ (e) => { let tempParams = {...params}; _.set(tempParams, item.name, e.target.value); setParams(tempParams) } } required={ item.required ? true : false }/>
            <button className="btn" onClick={ (e) => { e.preventDefault(); getSlug(item.name) } }>Generate</button>
          </div>
        </div>
      )
    } else if (item.type === "date") {
      const dateTimeLocalValue = !_.get(params, item.name) ? new Date() : new Date(_.get(params, item.name));
      return (
        <div key={ i } data-moduletype={item.name.split(".")[item.name.split(".").length-1]} data-moduleindex={i}>
          <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
          { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
          <DatePicker
            selected={dateTimeLocalValue}
            onChange={(dt) => {
              let tempParams = {...params};
              const dUTC = dt.toISOString()
              _.set(tempParams, item.name, dUTC);
              setParams(tempParams) 
            }}
            locale="pl"
            showTimeSelect
            timeFormat="p"
            timeIntervals={15}
            dateFormat="Pp"
            timeCaption="Hour"
          />
        </div>
      )
    } else if (item.type === "datecontest") {
      const dateTimeLocalValue = !_.get(params, item.name) ? new Date() : new Date(_.get(params, item.name));
      return (
        <div key={ i } data-moduletype={item.name.split(".")[item.name.split(".").length-1]} data-moduleindex={i}>
          <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
          { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
          <DatePicker
            selected={dateTimeLocalValue}
            onChange={(dt) => {
              let tempParams = {...params};
              const dUTC = dt.toISOString()
              _.set(tempParams, item.name, dUTC);
              setParams(tempParams) 
            }}
            locale="pl"
            showTimeSelect
            timeFormat="p"
            timeIntervals={15}
            dateFormat="Pp"
            timeCaption="Hour"
          />
        </div>
      )
    } else if (item.type === "modules") {
      return (
        <div key={ i } data-moduletype={item.name.split(".")[item.name.split(".").length-1]} data-moduleindex={i} className="title">
          <SingleUseModules availableModules={ data.singleUseModules } API={ API } items={ _.get(params, item.name) } changeModuleItems={ (items) => {
            let tempParams = _.cloneDeep(params)
            _.set(tempParams, item.name, items)
            setParams(tempParams)
          } }/>
          <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
          { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
          <Modules availableModules={ data.availableModules } API={ API } items={ _.get(params, item.name) } changeModuleItems={ (items) => {
            let tempParams = _.cloneDeep(params)
            _.set(tempParams, item.name, items)
            setParams(tempParams)
          } } addModule={ () => {
            let tempParams= _.cloneDeep(params)
            let tempModules = _.get(tempParams, item.name)
            tempModules.push({ type: "", custom: ""})
            _.set(tempParams, item.name, tempModules)
            setParams(tempParams)
            } } onFilesChange={ (files) => {  } }/>
        </div>
      )
    } else if (item.type === "category") {
      return (
        <div key={ i } data-moduletype={item.name.split(".")[item.name.split(".").length-1]} data-moduleindex={i} className="title">
          <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
          { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
          <Categories API={ API } items={ _.get(params, item.name) } selectCategory={ (cat) => { 
            let tempParams = {...params}
            _.set(tempParams, item.name, cat)
            setParams(tempParams)
          } } selectedCat={ _.get(params, item.name)|| {} } required={ item.required ? true : false }/>
        </div>
      )
    } else if (item.type === "category_offer") {
      return (
        <div key={ i } data-moduletype={item.name.split(".")[item.name.split(".").length-1]} data-moduleindex={i} className="title">
          <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
          { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
          <CategoriesOffers API={ API } items={ _.get(params, item.name) } selectCategory={ (cat) => { 
            let tempParams = {...params}
            _.set(tempParams, item.name, cat)
            setParams(tempParams)
          } } selectedCat={ _.get(params, item.name) || {} } required={ item.required ? true : false }/>
        </div>
      )
    } else if (item.type === "category_faq") {
      return (
        <div key={ i } data-moduletype={item.name.split(".")[item.name.split(".").length-1]} data-moduleindex={i} className="title">
          <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
          { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
          <CategoriesFAQ API={ API } items={ _.get(params, item.name) } selectCategory={ (cat) => { 
            let tempParams = {...params}
            _.set(tempParams, item.name, cat)
            setParams(tempParams)
          } } selectedCat={ _.get(params, item.name) || {} } required={ item.required ? true : false }/>
        </div>
      )
    } else if (item.type === "select_track") {
      return (
        <div key={ i } data-moduletype={item.name.split(".")[item.name.split(".").length-1]} data-moduleindex={i} className="title">
          <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
          { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
          <SelectTrack articleUUID={ id } API={ API } items={ _.get(params, item.name) } selectTrack={ (track) => { 
            let tempParams = {...params}
            if(track === "") {
              track = null
            } else {
              track = {uuid: track}
            }
            _.set(tempParams, item.name, track)
            setParams(tempParams)
          } } selectedTr={ _.get(params, item.name) || {} } required={ item.required ? true : false }/>
        </div>
      )
    } else if (item.type === "select_article") {
      return (
        <div key={ i } data-moduletype={item.name.split(".")[item.name.split(".").length-1]} data-moduleindex={i} className="title">
          <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
          { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
          <SelectArticle API={ API } items={ _.get(params, item.name) } selectItem={ (item) => { 
            let tempParams = {...params}
            item = {uuid: item}
            _.set(tempParams, item.name, item)
            setParams(tempParams)
          } } selected={ _.get(params, item.name) || {} } required={ item.required ? true : false }/>
        </div>
      )
    } else if (item.type === "select_offer") {
      return (
        <div key={ i } data-moduletype={item.name.split(".")[item.name.split(".").length-1]} data-moduleindex={i} className="title">
          <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
          { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
          <SelectOffer API={ API } items={ _.get(params, item.name) } selectItem={ (item) => { 
            let tempParams = {...params}
            item = {uuid: item}
            _.set(tempParams, item.name, item)
            setParams(tempParams)
          } } selected={ _.get(params, item.name) || {} } required={ item.required ? true : false }/>
        </div>
      )
    } else if (item.type === "select_event") {
      return (
        <div key={ i } data-moduletype={item.name.split(".")[item.name.split(".").length-1]} data-moduleindex={i} className="title">
          <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
          { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
          <SelectEvent API={ API } items={ _.get(params, item.name) } selectItem={ (item) => { 
            let tempParams = {...params}
            item = {uuid: item}
            _.set(tempParams, item.name, item)
            setParams(tempParams)
          } } selected={ _.get(params, item.name) || {} } required={ item.required ? true : false }/>
        </div>
      )
    } else if (item.type === "address") {
      return (
        <div key={ i } data-moduletype={item.name.split(".")[item.name.split(".").length-1]} data-moduleindex={i} className="title">
          <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
          { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
          <Addresses API={ API } items={ _.get(params, item.name) } selectAddress={ (addr) => { 
            let tempParams = {...params}
            _.set(tempParams, item.name, {uuid: addr})
            setParams(tempParams)
          } } selectedAddr={ _.get(params, item.name)?.uuid || {} } required={ item.required ? true : false }/>
        </div>
      )
    } else if (item.type === "subtitle") {
      return (
        <div key={ i } data-moduletype={item.name.split(".")[item.name.split(".").length-1]} data-moduleindex={i} className="title">
          <h2>{ item.value }</h2>
        </div>
      )
    } else if (item.type === "null") {
      return (
        <div key={ i } data-moduletype={item.name.split(".")[item.name.split(".").length-1]} data-moduleindex={i} className="null"></div>
      )
    } else if (item.type === "number") {
      return (
        <div key={ i } data-moduletype={item.name.split(".")[item.name.split(".").length-1]} data-moduleindex={i}>
          <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
          { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
          <input type="number" name={ item.name } value={ _.get(params, item.name) } onChange={ (e) => { let tempParams = {...params}; _.set(tempParams, item.name, e.target.value); setParams(tempParams) } } required={ item.required ? true : false }/>
        </div>
      )
    } else if (item.type === "checkbox") {
      return (
        <div key={ i } data-moduletype={item.name.split(".")[item.name.split(".").length-1]} data-moduleindex={i} className="checkbox">
          <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
          { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
          <input type="checkbox" id={ i } name={ item.name } onChange={ (e) => { let tempParams = {...params}; _.set(tempParams, item.name, !_.get(params, item.name) || false); setParams(tempParams) } } checked={ _.get(params, item.name) }/>
          <label htmlFor={ i }></label>
        </div>
      )
    } else if(item.type === "textarea") {
      return (
        <div key={ i } data-moduletype={item.name.split(".")[item.name.split(".").length-1]} data-moduleindex={i}>
          <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
          { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
          <textarea name={ item.name } value={ _.get(params, item.name) } onChange={ (e) => { let tempParams = {...params}; _.set(tempParams, item.name, e.target.value); setParams(tempParams) } } required={ item.required ? true : false }></textarea>
        </div>
      )
    } else if(item.type === "multiselect") {
      return (
        <div key={ i } data-moduletype={item.name.split(".")[item.name.split(".").length-1]} data-moduleindex={i}>
          <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
          { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
          <MultiselectComponent API={API} table={item.table} endpoint={item.endpoint} selected={_.get(params, item.name) || []} select={ (e) => { let tempParams = {...params}; _.set(tempParams, item.name, e); setParams(tempParams) } }/>
        </div>
      )
    } else if(item.type === "terms") {
      let val = _.get(params, item.name)

      if (!val.file) {
        val.file = {uuid: "", restricted: params.restricted}
      }

      return (
        <div key={ i } data-moduletype={item.name.split(".")[item.name.split(".").length-1]} data-moduleindex={i}>
          <h4>{ item.placeholder }{ item.required ? <span>*</span> : null }</h4>
          { item.desc ? <p className="input-desc">{ item.desc }</p> : null }
          <AddTerm restricted={params.restricted} API={ API } image={ val.file } onChange={ (track) => {
            val.file = track
            val.file.restricted = params.restricted
            let tempParams = {...params}
            _.set(tempParams, item.name, val.file)
            setParams(tempParams)
            } }/>
        </div>
      )
    } else {
      return false
    }
  })

  return (
    <section className="new">
      <div className="container">
        <h1>{ data.name } - edit</h1>
        <div className="date">
            { params.created ? <span><strong>Created:</strong> { parseDate(params.created, true, true) }<br/></span> : null }
            { params.updated ? <span><strong>Edited:</strong> { parseDate(params.updated, true, true) }<br/></span> : null }
            { params.publicationDate ? <span><strong>Publich date:</strong> { parseDate(params.publicationDate, true, true) }</span> : null }
          </div>
        <form className="fields">
          { user.role === "ADMIN" || user.role === "Moderator" ? <div className="add-top" onClick={ () => { validateForm(save) } }>Save</div> : null }
          <div className="back-top" onClick={ () => { history(-1) } }>Go back</div>
          { params.basePrice || params.basePrice === 0 || params.perUserLimit || params.taxPrice || params.group ? <div className="info-box">
            { params.basePrice || params.basePrice === 0 ? <p>Cost: <strong>{ params.basePrice } pts</strong></p> : null }
            { params.taxPrice ? <p>Value: <strong>{ params.taxPrice } zł</strong></p> : null }
            { params.group ? <p>Group: <strong>{ params.group }</strong></p> : null }
            { params.perUserLimit ? <p>User limit: <strong>{ params.perUserLimit }</strong></p> : null }
          </div> : null }
          { fields }
          { user.role === "ADMIN" || user.role === "Moderator" ? <div className="add-bottom" onClick={ () => { validateForm(save) } }>Save</div> : null }
          <div className="back-bottom" onClick={ () => { history(-1) } }>Go back</div>
          { preloader ? <div className="preloader"><span></span></div> : null }
        </form>
      </div>
    </section>
  )
}