/* Component for easier ES6 binding */

import { Component } from 'react';

export const API = process.env.REACT_APP_API_URL

export default class BaseComponent extends Component {

	// The rest parameter syntax allows to represent an indefinite number of arguments as an array.
	// https://developer.mozilla.org/pl/docs/Web/JavaScript/Reference/Functions/rest_parameters
	_bind(...methods) {
		methods.forEach( (method) => this[method] = this[method].bind(this) );
	}

	setContent(id) {
		this.setState({ index : id })
	}

	setWrapperHeight(h) {
		this.props.setHeight(h)
	}
}

export function leadingZero(day) {
	let d = parseInt(day);
	if (d < 10) {
	  return "0" + d;
	} else {
	  return "" + d;
	}
  }
  
  export function parseDate(d, hours, showSeconds) {
	let date = new Date(d);
	let seconds = ":" + leadingZero(date.getSeconds());
  
	if (showSeconds === false) {
	  seconds = "";
	}
  
	if (hours) {
	  return (
		leadingZero(date.getDate()) +
		"/" +
		leadingZero(date.getMonth() + 1) +
		"/" +
		date.getFullYear() +
		", " +
		leadingZero(date.getHours()) +
		":" +
		leadingZero(date.getMinutes()) +
		seconds
	  );
	} else {
	  return (
		leadingZero(date.getDate()) +
		"." +
		leadingZero(date.getMonth() + 1) +
		"." +
		date.getFullYear()
	  );
	}
  }

export function errorInputValidator(error) {
	if(error?.includes("Validator")) {
		const type = error.split("/")[error.split("/").length-1].split("_")[0]
		const index = error.split("/")[error.split("/").length-1].split("_")[1]

		if(index) {
			setTimeout(() => { document.querySelector("[data-moduleindex='" + index + "'][data-moduletype='" + type + "']")?.scrollIntoView({behavior: "smooth", block: "center", inline: "center"})
				setTimeout(() => {
					document.querySelector("[data-moduleindex='" + index + "'][data-moduletype='" + type + "']")?.classList.add("input-error")
					if(document.querySelector("[data-moduleindex='" + index + "'][data-moduletype='" + type + "'] .ck-content")) {
						document.querySelector("[data-moduleindex='" + index + "'][data-moduletype='" + type + "'] .ck-content").focus()
					}
					setTimeout(() => {
						document.querySelector("[data-moduleindex='" + index + "'][data-moduletype='" + type + "']")?.classList.remove("input-error")
					}, 3000)
				}, 300)
			}, 300)
		} else {
			setTimeout(() => { document.querySelector("[data-moduletype='" + type + "']")?.scrollIntoView({behavior: "smooth", block: "center", inline: "center"})
				setTimeout(() => {
					document.querySelector("[data-moduletype='" + type + "']")?.classList.add("input-error")
					if(document.querySelector("[data-moduletype='" + type + "'] .ck-content")) {
						document.querySelector("[data-moduletype='" + type + "'] .ck-content").focus()
					}
					setTimeout(() => {
						document.querySelector("[data-moduletype='" + type + "']")?.classList.remove("input-error")
					}, 3000)
				}, 300)
			}, 300)
		}
	}
}
  
export function errorMap(error) {
	const ERRORS = {
		"error_article_not_found": "error_article_not_found",
		"error_articlecategory_in_use": "error_articlecategory_in_use",
		"error_articlecategory_not_found": "error_articlecategory_not_found",
		"error_entity_not_found": "error_entity_not_found",
		"error_event_finish_before_start": "error_event_finish_before_start",
		"error_event_not_found": "error_event_not_found",
		"error_event_optin_after_start": "error_event_optin_after_start",
		"error_event_optout_after_start": "error_event_optout_after_start",
		"error_event_optout_before_optin": "error_event_optout_before_optin",
		"error_faq_not_found": "error_faq_not_found",
		"error_faqcategory_in_use": "error_faqcategory_in_use",
		"error_faqcategory_not_found": "error_faqcategory_not_found",
		"error_file_not_found": "error_file_not_found",
		"error_file_size_exceeded": "error_file_size_exceeded",
		"error_file_type_not_permitted": "error_file_type_not_permitted",
		"error_history_not_found": "error_history_not_found",
		"error_insufficient_permissions": "error_insufficient_permissions",
		"error_message_ident_not_found": "error_message_ident_not_found",
		"error_missing_file": "error_missing_file",
		"error_notification_for_object_already_exists": "error_notification_for_object_already_exists",
		"error_notification_not_found": "error_notification_not_found",
		"error_offer_not_found": "error_offer_not_found",
		"error_offercategory_in_use": "error_offercategory_in_use",
		"error_offercategory_not_found": "error_offercategory_not_found",
		"error_public_after_in_the_past": "error_public_after_in_the_past",
		"error_slug_taken": "error_slug_taken",
		"error_token_expired": "error_token_expired",
		"error_track_not_found": "error_track_not_found",
		"error_track_taken": "error_track_taken",
		"error_unauthorized": "error_unauthorized",
		"error_unknown_provider": "error_unknown_provider",
		"error_user_exists": "error_user_exists",
		"error_user_not_found": "error_user_not_found",
		"RequiredStringValidator: article/category": "Nie wybrano kategorii."
	}

	return ERRORS[error] || error
}